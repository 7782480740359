.table.dataTable {
    border-collapse: collapse !important;
}

.dataTables_wrapper {
    padding: 0;
}

.dataTables_info {
    white-space: normal !important;
}

.dtrg-group th {
    font-weight: 400 !important;
}

.dtrg-level-0 th {
    background-color: #fff3e0 !important;
}

.dtrg-level-1 th {
    background-color: #f1f8e9 !important;
}

.dtgr-inf {
    font-weight: 700;
}

.dt-inf-search,
.dt-inf-filter-select {
    width: 100%;
    font-weight: 400;
    border: 1px solid #b4babe;
    border-radius: 0.25rem;
    color: #000;
    padding: 0.125rem 0.5rem;
    font-size: 1rem;
    box-sizing: border-box;
    vertical-align: top;
}

.dt-inf-filter-select {
    appearance: none;
}

.dt-inf-search::placeholder,
.dt-inf-filter-select.placeholder {
    color: #6c757d;
}

.dataTable .search,
.dataTable .filter-select {
    padding: 0.25rem;
}

.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp table.dtp-picker-days tr > td > a.selected,
.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
    background: $color-primary;
}

.dtp .p10 > a {
    color: #fff;
}

.dtsp-subRowsContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dtsp-panesContainer {
    margin: 0.5rem 0 !important;
}

@include media-breakpoint-down(md) {
    .dataTables_wrapper .dataTables_filter,
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_paginate {
        text-align: right !important;
    }

    .dataTables_wrapper .col-sm-12 {
        padding: 0 !important;
    }

    .datagrid {
        padding: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter,
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
        text-align: right !important;
    }
}

@include media-breakpoint-up(md) {
    .dataTables_info,
    .dataTables_length {
        float: left;
    }

    .dataTables_filter,
    .dataTables_paginate {
        float: right;
    }
}
