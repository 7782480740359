i.darkmode-icone:before {
  content: "\f186";
}

.bootstrap-dark {
  background: #1a1a1a;
  color: #b8b8b8;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #c3c3c3;
  }
  .card,
  .left-sidebar .sidebar-nav ul,
  .left-sidebar,
  .topbar .top-navbar .navbar-header,
  .left-sidebar .sidebar-footer,
  .topbar
  {
    background: #2c2c2c;
  }
  .left-sidebar .sidebar-footer {
    border-top-color: #646464;
  }
  .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link:hover {
    color: #fff;
  }
  .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link {
    color: #aaa;
  }
  .left-sidebar .sidebar-footer a, .left-sidebar .user-profile .profile-text a {
    color: #aaa;
  }
  .left-sidebar .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active, .left-sidebar .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active i {
    color: #fff;
  }
  .navbar-collapse {
    background: #1a1a1a !important;
    background: linear-gradient(to right, #0e1439 0, #2e1332 100%) !important;
  }

  .footer {
    background: #212121;
    border-top-color: #2c2c2c;
  }

  i.darkmode-icone:before {
    content: "\f185";
  }
  .v-avatar svg circle,
  #snippet--avatar svg circle,
  #snippet--avatar3 svg circle,
  #snippet--foto svg circle,
  .col-avatar svg circle {
    fill: #646464 !important;
  }
  .v-avatar svg text,
  #snippet--avatar svg text,
  #snippet--avatar3 svg text,
  #snippet--foto svg text,
  .col-avatar svg text {
    fill: #ccc;
  }

  input:not(.btn), .custom-select, .form-control,
  .select2-container--default .select2-selection--single,
  .dt-inf-filter-select, .dt-inf-search {
    border: 1px solid #1a1a1a;
    background-color: #646464;
    color: #eee;
  }
  input:not([disabled]).no-autocomplete {
    background-color: #646464 !important;
  }
  .v-input__control input {
    border: none;
    background-color: transparent;
  }

  .fefdBw > .nav-tabs {
    background-color: #590d0d !important;
  }

  input::placeholder, .custom-select::placeholder, textarea::placeholder, .v-field__input::placeholder,
  .dp__input_reg::placeholder{
    color: #1a1a1a !important;
  }
  input:focus::placeholder, .custom-select:focus::placeholder {
    color: #000 !important;
  }

  .v-btn__content {
    color: #aaaaaa;
  }
  .events-component__wrapper, .events-timeline__loading {
    background: #545454;
  }

  .select2-dropdown {
    background-color: #1a1a1a;
    border: 1px solid #000;
  }
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
  .select2-container--default .select2-selection--multiple {
    background-color: #646464;
    border-color: #000;
  }
  .select2-container--default .select2-results__option--selected {
    background-color: #545454;
  }
  .select2-container--classic .select2-selection--single, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow, .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #ffffff;
  }
  .select2-container--default.select2-container--focus .select2-selection,
  .dt-inf-filter-select, .dt-inf-search {
    border-color: #000 !important;
    box-shadow: 0 0 .2rem .2rem rgba(94, 94, 94, 0.25) !important;
  }

  .stitky-select2 .select2-selection__choice__remove {
    color: #aaa !important;
    background-color: #3c3c3c !important;
    border-color: #000000 !important;
  }
  .stitky-select2 .select2-selection__choice {
    background-color: #3c3c3c !important;
    border-color: #000000 !important;
    color: #aaa !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    background-color: #000000 !important;
  }
  .bg-white {
    background-color: #646464 !important;
  }
  label.text-muted {
    color: #aaa !important;
  }
  .v-card__title.text-h5 {
    color: #c3c3c3 !important;
  }
  .v-tab.v-tab--active {
    color: #c3c3c3 !important;
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #252525;
    color: #aaaaaa !important;
  }
  .theme--light.v-expansion-panels .theme--light.v-icon,
  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .v-tabs-slider-wrapper {
    color: rgba(231, 220, 220, 0.8) !important;
  }
  .theme--light.v-select .v-select__selections {
    color: #fff;
  }
  .theme--light.v-application {
    color: #ccc;
  }
  .btn-outline-secondary {
    color: #fff;
  }
  .container {
    .theme--light.v-chip:not(.v-chip--active) {
     background: #525252;
     color: #ccc;
    }
    .v-chip__content .v-avatar {
     background-color: #aaaaaa !important;
     color: #111111;
    }
    .theme--light.v-card>.v-card__subtitle, .theme--light.v-card>.v-card__text {
     color: #ccc;
    }
  }
  div#IconPickerModal .ip-icons-content {
    background: #3a3a3a;
    .ip-icons-area:before {
      box-shadow: 0 0 50px 25px #373636bf;
      background: #393636cc;
    }
    .ip-icons-area>i:hover, div#IconPickerModal .ip-icons-content .ip-icons-search-results>*>i:hover {
      background: #000000;
    }
  }
  div#IconPickerLoading {
    background: #3a3a3a;
  }

  .custom-file-label {
    color: #fff;
    background-color: #646464;
    border: 1px solid #1a1a1a;
  }
  .custom-file-label::after {
    color: #aaa;
    background-color: #545454;
    border-left: inherit;
  }

  .custom-control-label::before {
    background-color: #1a1a1a;
  }

  .v-select__selections input {
    border: 0;
  }

  option {
    background: #2c2c2c;
    color: #ccc;
  }

  .form-control:focus, input:focus {
    color: #fff;
    //background-color: #515151;
    border-color: #646464 !important;
    box-shadow: 0 8px 15px rgba(134, 142, 150, .3) !important;
    //box-shadow: 0 0 .2rem .2rem rgba(100, 100, 100, 0.25) !important;
  }


  .search-input {
    color: #aaa;
    background: #646464;
  }
  .search-input__dropdown-item--active[data-v-fd2ae9bf], .search-input__dropdown-item--active[data-v-fd2ae9bf]:hover {
    background: #646464;
  }
  .search-input__dropdown {
    background: #2c2c2c;
  }
  .search-input__wrapper {
    color: #646464;
  }

  .page-link {
    background-color: #2c2c2c;
    border: 1px solid #aaa;
    color: #aaa;
  }
  .page-link:hover {
    background-color: #505050;
    border-color: #646464;
  }
  .page-link:focus {
    box-shadow: 0 8px 15px rgba(134, 142, 150, .3);
  }
  .page-item.disabled .page-link {
    color: #6c757d;
    background-color: #1a1a1a;
    border-color: #646464;
  }
  .page-item.active .page-link {
    background-color: #aaa !important;
    color: #000;
    border-color: #aaa !important;
  }

  .filepond--panel-root {
    border-radius: .5em;
    background-color: #646464;
  }



  .modal-content {
    background: #2c2c2c;
  }
  .modal-dialog .modal-title {
    color: #aaa;
  }
  .bg-modal-light {
    background: #3d3d3d !important;
  }
  .modal-header {
    border-bottom-color: #646464;
  }
  .modal-footer {
    border-top-color: #646464;
  }
  .close {
    color: #fff;
  }

  .custom-select {
    background: url(../../assets/images/custom-select.png) right .75rem center / 8px no-repeat #646464;
  }

  .v-input .v-icon {
    color: #aaa !important;
  }
  .theme--light.v-btn.v-btn--icon {
    color: #646464;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    border: 1px solid #1a1a1a !important;
  }
  .theme--light.v-text-field--solo>.v-input__control>.v-input__slot, .theme--light.v-input input:focus {
    background: #646464;
    color: #fff;
  }
  .page-breadcrumb .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    background: #646464 !important;
  }
  .theme--light.v-sheet,
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
    background: #2c2c2c !important;
    color: #aaa !important;
  }
  .theme--light.v-list-item--active {
    background: #646464 !important;
    color: #fff !important;
  }
  .theme--light.v-list-item:hover {
    background: #646464 !important;
    color: #ccc !important;
  }
  .theme--light.v-label {
    color: #aaa;
  }
  .theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
    color: #fff;
  }
  .theme--light.v-btn.v-btn--has-bg {
    background-color: #535353;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #535353 !important;
    color: #646464 !important;
  }
  .theme--light.v-picker__body {
    background: #000000;
  }
  .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #646464;
  }
  .theme--light.v-btn {
    color: #646464;
  }
  .theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__action-text, .theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle {
    color: #646464;
  }
  .theme--light.v-date-picker-table .v-date-picker-table--date__week, .theme--light.v-date-picker-table th {
    color: #646464;
  }
  #widgety .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    background: #646464 !important;
  }

  .dropdown-colorselector>.dropdown-toggle {
    border: 1px solid #000000;
  }

  .v-application .primary--text.text--darken-2 {
    color: #448f38 !important;
    caret-color: #d6d6d6 !important;
  }

  .v-tab--disabled, .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn, .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
    color: #aaa;
  }

  .v-field__field input {
    border-width: 0 !important;
    margin-left: 5px;
  }
  .v-field__overlay {
    background-color: #646464;
  }
  .v-field__overlay input {
    background-color: #646464 !important;
  }
  .business-select .v-input__control .v-field {
    border: #000;
  }

  .v-menu > .v-overlay__content > .v-card, .v-menu > .v-overlay__content > .v-sheet, .v-menu > .v-overlay__content > .v-list {
    background: rgb(var(--v-theme-surface));
  }

  .v-input__control input:focus {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  div.dtsp-panesContainer div.dataTables_wrapper div.dataTables_scrollBody,
  div.DTS div.dataTables_scrollBody table {
    background: #2c2c2c !important;
    color: #aaa;
  }

  div.dtsp-panesContainer div.dtsp-searchPane div.dataTables_wrapper {
    border: 2px solid #f0f0f0;
  }

  table.dataTable>tbody>tr.selected>* {
    box-shadow: inset 0 0 0 9999px #646464;
    color: #fff;
  }

  table.dataTable tbody>tr.selected, table.dataTable tbody>tr>.selected {
    background-color: #646464;
    color: #2c2c2c;
  }

  table.dataTable thead tr>.dtfc-fixed-left,table.dataTable thead tr>.dtfc-fixed-right,table.dataTable tfoot tr>.dtfc-fixed-left,table.dataTable tfoot tr>.dtfc-fixed-right ,
  table.dataTable tbody tr>.dtfc-fixed-left,table.dataTable tbody tr>.dtfc-fixed-right,
  div.dtfc-left-top-blocker,div.dtfc-right-top-blocker {
    background-color: #2c2c2c;
  }

  .table {
    color: #ccc;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
  .table-hover tbody tr:hover {
    color: #bbb;
    background: #212121 !important;
  }
  .table-bordered, .table-bordered td, .table-bordered th {
    border: 1px solid #646464 !important;
  }

  .page-wrapper, .chat-toggle {
    background: #1a1a1a;
    color: #aaa;
  }
  a:not([class]) {
    color: #38bcc5;
  }
  a:not([class]):hover {
    color: #fff;
  }

  .message-title {
    color: #ccc !important;
  }

  ul.list-style-none li a {
    color: #ccc;
  }

  .mailbox .message-center .message-item.active, .mailbox .message-center .message-item:hover {
    color: #000;
  }

  .text-dark {
    color: #848484 !important;
  }

  .btn-light, .btn-outline-light {
    box-shadow: 0 2px 2px rgba(235, 243, 245, .05);
  }
  .btn-dark {
    background-color: #000;
    color: #ccc;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 8px 15px rgba(134, 142, 150, .3);
  }

  .btn-light {
    color: #212529;
    background-color: #aaa;
    border-color: #1a1a1a;
  }
  .bg-light {
    background: #3d3d3d !important;
    color: #ccc;
  }

  .clientTabs {
    background: #1a1a1a !important;
  }
  .nav-tabs .nav-link {
    color: #aaa;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link:focus {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #646464 #646464 #2c2c2c;
  }
  .nav-tabs {
    border-bottom: 1px solid #646464;
  }
  .nav-tabs .nav-link:not(.active):hover {
    isolation: isolate;
    border-color: #646464 #646464 #646464;
  }

  .border-left {
    border-left: 1px solid #646464 !important;
  }

  hr {
    border-top: 1px solid #646464;
  }

  .bubble-info {
    background-color: #3a3a3a;
    color: #ccc;
  }

  .preloader {
    background-color: #1a1a1a;
  }

  .ui-widget-content {
    border: 1px solid #646464;
    background: #2c2c2c;
    color: #fff;
  }

  .filter-business {
    background: #2c2c2c;
  }
  .business-select .v-input__control .v-field {
    border: 1px solid #000000;
  }

  .datagrid {
    background-color: #2c2c2c;
  }

  .input-group-text {
    color: #aaa !important;
    background-color: #494949 !important;
    border: 1px solid #000 !important;
  }

  .datagrid .row-grid-bottom,
  .datagrid table thead tr.row-group-actions th {
    background-color: #494949;
  }

  .dropdown-menu {
    background: #000;
    color: #aaa;
  }
  .dropdown-item {
    color: #aaa;
  }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #646464;
  }

  .old-month {
    background-color: #3a3a3a !important;
  }
  .current-month {
    background-color: #646464 !important;
  }
  .bg-green {
    background-color: #567b4a !important;
  }



  .datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
    background: #646464;
    color: #fff;
  }
  .datepicker .datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover, .datepicker tfoot tr th:hover {
    background: #646464;
    color: #fff;
  }
  .datepicker table tr td span:hover, .datepicker table tr td span.focused {
    background: #646464;
    color: #fff;
  }
  .datepicker table tr td.highlighted {
    background: #2c2c2c;
  }
  .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
    background-color: #545454;
  }
  .datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today:active, .datepicker table tr td.today:hover:active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today[disabled], .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #646464;
  }
  .dropdown-menu.dropdown-caret:after,
  .datepicker-dropdown:after,
  .dropdown-menu.dropdown-caret:before,
  .datepicker-dropdown:before, .datepicker-dropdown:after,
  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #000000;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid #000000;
  }

  .noUi-target {
    background: #646464;
    border: 1px solid #646464;
    box-shadow: inset 0 1px 1px #646464, 0 3px 6px -5px #BBB;
  }

  .flatpickr-calendar {
    background: #000000;
    -webkit-box-shadow: 1px 0 0 #646464, -1px 0 0 #646464, 0 1px 0 #646464, 0 -1px 0 #646464, 0 3px 13px #000;
    box-shadow: 1px 0 0 #646464, -1px 0 0 #646464, 0 1px 0 #646464, 0 -1px 0 #646464, 0 3px 13px #000;
  }
  .flatpickr-months .flatpickr-month {
    color: #aaa;
    fill: #aaa;
  }
  span.flatpickr-weekday, .flatpickr-day  {
    color: #aaa;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: #000;
  }

  .daterangepicker {
    color: #aaa;
    background-color: #000000;
    border: 1px solid #595959;
  }
  .daterangepicker .calendar-table {
    border: 1px solid #646464;
    border-radius: 4px;
    background-color: #2c2c2c;
  }
  .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #1c1c1c;
    color: #646464;
  }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #646464;
    color: #fff;
  }
  .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #aaa;
    color: #fff;
  }
  .daterangepicker td.in-range {
    background-color: #646464;
    border-color: transparent;
    color: #fff;
  }
  .daterangepicker:before {
    border-right: 7px solid transparent;
    border-left: 7px solid transparent !important;
    border-bottom: 7px solid #646464;
  }
  .daterangepicker:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid #646464 !important;
    border-left: 6px solid transparent;
  }

  .dp__menu {
    background: #000;
    border: 1px solid #646464;
  }
  .dp__arrow_top {
    background-color: #000;
    border-left: 1px solid #646464;
    border-top: 1px solid #646464;
  }
  .dp__menu:focus {
    border: 1px solid #aaa;
  }
  .dp__month_year_select {
    color: #aaa;
  }
  .dp__calendar_item {
    color: #aaa;
  }
  .dp__cell_offset {
    color: #646464;
  }
  .dp__today {
    border: 1px solid #aaa;
  }
  .dp__calendar_header {
    color: #646464;
  }
  .dp__range_end, .dp__range_start, .dp__active_date {
    background: #aaa;
    color: #000;
  }
  .dp__range_between {
    background: var(--dp-hover-color);
    border-radius: 0;
    border: 1px solid var(--dp-hover-color);
  }
  .dp__range_between {
    background: #2c2c2c;
    border: 1px solid #2c2c2c;
  }
  .dp__overlay {
    background: #000000;
    color: #8e8e8e;
  }
  .dp__overlay_cell:hover, .dp__month_year_select:hover, .dp__cell_in_between {
    background: #aaa;
  }
  .dp__calendar_header_separator {
    background: #646464;
  }

  div:where(.swal2-container) div:where(.swal2-popup) {
    background: #000;
  }

  #frappe {
    background: #2c2c2c !important;
  }
  #frappe .editor table th {
    background: #2c2c2c !important;
  }
  #frappe .editor table th {
    border: 1px solid #646464;
  }
  #frappe .editor table th:first-child {
    border-left: 1px solid #646464;
  }
  .gantt .grid-row {
    fill: #2c2c2c;
  }
  .gantt .grid-header {
    fill: #2c2c2c;
    stroke: #e0e0e0;
  }
  .gantt .bar-wrapper.active .bar {
    fill: #a9b5c1
  }

  .gantt .lower-text,.gantt .upper-text {
    font-size: 12px;
    text-anchor: middle
  }
  .gantt .upper-text {
    fill: #555
  }
  .gantt .lower-text {
    fill: #919191;
  }

  #activity {
    background-color: #2c2c2c;
  }
  .theme--light.v-tabs>.v-tabs-bar {
    background-color: #2c2c2c;
  }
  .theme--light.v-tabs-items {
    background-color: #2c2c2c;
  }

  .roles-menu .nav {
    background-color: #2c2c2c;
  }
  .roles-menu .nav>li a {
    color: #aaa;
  }
  .list-group-item {
    background-color: #3d3d3d;
  }

  .chat-box {
    background: #000000;
  }
  .chat-box-header-name span:first-child {
    color: #ccc;
  }

  .dmsAppWrapper {
    .theme--light.v-app-bar.v-toolbar.v-sheet {
      background-color: #f5f5f5 !important;
    }
  }

  .fc .fc-scrollgrid-section-sticky > *,
  .fc-day {
    background: #2c2c2c;
  }
  .fc-theme-standard .fc-list,
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #646464;
  }
  .fc-theme-standard .fc-list-day-cushion {
     background-color: #1a1a1a;
  }
  #calendar-wrapper .fc-list-event {
    background-color: #2c2c2c;
    color: #aaa !important;
  }
  #calendar-wrapper .fc-list-event-graphic {
    background-color: #1c1c1c;
  }
  #calendar-wrapper .fc .fc-list-event:hover td,
  .fc-event:hover {
    background: #1c1c1c !important;
  }

  .popover {
    background-color: #000000;
  }
  .bs-popover-auto[x-placement^=right]>.arrow::after, .bs-popover-right>.arrow::after {
    border-right-color: #000000;
  }
  .popover-header {
    background-color: #000000;
    border-bottom: 1px solid #646464;
  }
  .popover-body {
    color: #aaa;
  }
  .bs-popover-auto[x-placement^=left]>.arrow::after, .bs-popover-left>.arrow::after {
    border-left-color: #000000;
  }

  .card .card-title {
    color: #c3c3c3;
  }

  .place-message-detail
  {
    background-color: #1a1a1a !important;
  }

  .progress {
    background-color: #646464;
  }

  .badge {
    color: #3c3c3c;
  }

  .image-area {
    border-color: transparent;
  }

  @media (max-width: 767.98px) {
    .top-navbar .navbar-collapse, .top-navbar .navbar-collapse.collapsing {
      background: #2c2c2c !important;
      color: #646464;
    }
  }
}