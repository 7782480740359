.btn-circle:hover .fa-plus {
  transition: transform 0.5s ease;
  transform: rotate(270deg);
}

.nav-link {
  padding: 0.4rem 0.8rem;
}

.clientTabs {
  background: #f2f7f8 !important;
}
.search-input {
  transition: width .5s ease-in-out !important;
}

.page-wrapper, .top-navbar .navbar-header {
  transition: none;
}
.top-navbar, .top-navbar .navbar-header {
  transition: width .2s ease-in;
}

.datagrid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.page-wrapper>.container-fluid, .page-wrapper>.container-lg, .page-wrapper>.container-md, .page-wrapper>.container-sm, .page-wrapper>.container-xl {
  min-height: calc(100vh - 204px);
}

div.dataTables_scrollBody, div.dataTables_scrollFootInner table.table-bordered tr th:first-child, div.dataTables_scrollHeadInner table.table-bordered tr th:first-child {
  border-left: 0 !important;
}

@media (min-width: 768px) {
  .page-wrapper>.container-fluid, .page-wrapper>.container-lg, .page-wrapper>.container-md, .page-wrapper>.container-sm, .page-wrapper>.container-xl {
    padding: 14px 13px;
  }
  .page-breadcrumb {
    padding: 14px 13px 0 !important;
  }
}